import "./App.css";

function App() {
  return (
    <div>
      <h1
        style={{
          marginLeft: "16px",
        }}
      >
        Page not found!
      </h1>
    </div>
  );
}

export default App;
